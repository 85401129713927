import defaultSettings from '@/settings'

const title = defaultSettings.title || '广告交易平台'

export default function getPageTitle(pageTitle) {
  let mytitle= title
  if(window.location.host =='admin.adwanji.com')
  {
    mytitle='美橙广告平台'
  }
  if(window.location.host =='adm.adbaixiang.com')
  {
    mytitle='百香果广告平台'
  }
  if(window.location.host =='ydtd.adwanji.com')
  {
    mytitle='悦动通达广告平台'
  }
  if (pageTitle) {
    return `${pageTitle} - ${mytitle}`
  }
  return `${mytitle}`
}
