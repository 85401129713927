import Vue from 'vue'

import 'normalize.css/normalize.css'// A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import VCharts from 'v-charts'


import moment from 'moment';
Vue.prototype.$moment = moment;//赋值使用

import '@/styles/index.scss' // global css

import App from './App'
import router from './router'
import store from './store'
import filters from './utils/filters'
import '@/icons' // icon
import '@/permission' // permission control

import funs from "@/utils/function/function"
import test from "@/utils/function/test"
Vue.prototype.$test = test;
Vue.prototype.$funs = funs;

import * as api from "@/api";
Vue.prototype.$api = api;

import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;

Vue.use(ElementUI, { locale })
Vue.use(VCharts)

Vue.config.productionTip = false
//挂载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import plugins from './utils/plugin'
Vue.use(plugins)



new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
