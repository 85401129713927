<template>
  <div >
    <el-menu class="navbar" mode="horizontal">
      <hamburger
        class="hamburger-container"
        :toggleClick="toggleSideBar"
        :isActive="sidebar.opened"
      ></hamburger>
      <breadcrumb></breadcrumb>

      <el-dropdown class="avatar-container" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          欢迎， {{ $store.getters.realname || $store.getters.name}}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="changePW">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout" divided>退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu>

    <ChangePwd ref="changePwd"></ChangePwd>
    <!-- <el-dialog
      title="设置登录帐户"
      :visible.sync="accountFormVisible"
      width="450px"
      @close="resetForm('accountForm')"
    >
      <el-form
        :model="accountForm"
        :rules="accountRules"
        ref="accountForm"
        size="mini"
        label-width="80px"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="accountForm.oldPassword"
            show-password
            type="password"
            :minlength="6"
            style="width:200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="accountForm.newPassword"
            show-password
            type="password"
            :minlength="6"
            style="width:200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :minlength="6" prop="confirmPassword">
          <el-input
            type="password"
            v-model="accountForm.confirmPassword"
            show-password
            autocomplete="off"
            style="width:200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('accountForm')">重置</el-button>
        <el-button type="primary" @click="handleAccountSubmit('accountForm')">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import ChangePwd from "@/components/ChangePwd"
export default {
  components: {
    Breadcrumb,
    Hamburger,
    ChangePwd
  },
  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入密码"));
    //   } else {
    //     if (this.accountForm.confirmPassword !== "") {
    //       this.$refs.accountForm.validateField("confirmPassword");
    //     }
    //     callback();
    //   }
    // };
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请再次输入密码"));
    //   } else if (value !== this.accountForm.newPassword) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      // accountForm: {
      //   newPassword: "",
      //   confirmPassword: "",
      //   oldPassword: ""
      // },
      // accountFormVisible: false,
      // accountRules: {
      //   oldPassword: [
      //     { required: true, message: "请输入旧密码", trigger: "blur" },
      //     { min: 6, max: 45, message: "长度在 6 到 45 个字符", trigger: "blur" }
      //   ],
      //   newPassword: [
      //     { validator: validatePass, trigger: "blur" },
      //     { min: 6, max: 45, message: "长度在 6 到 45 个字符", trigger: "blur" }
      //   ],
      //   confirmPassword: [{ validator: validatePass2, trigger: "blur" }]
      // }
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar"])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.$store.dispatch("user/LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
    handleCommand(command) {
      if (command == "logout") this.logout();
      if (command == "changePW") {
        const _this = this.$refs.changePwd;
        _this.accountFormVisible = true;
        // this.accountFormVisible = true;
        // this.accountForm = {
        //   newPassword: "",
        // confirmPassword: "",
        // oldPassword: ""
        // };
      }
    },
    // handleAccountSubmit(formName) {
    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       this.$api.changePassword(this.accountForm).then(res => {
    //         if (res.code == 200) {
    //           this.$message.success("密码修改成功");
    //         } else {
    //           this.$message.error(res.msg || "ERROR!");
    //         }
    //         this.accountFormVisible = false;
    //       });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      .user-name {
        height: 40px;
        line-height: 40px;
        float: right;
      }
      。user-logout {
        height: 40px;
        line-height: 40px;
        float: right;
      }
    }
  }
}
</style>

