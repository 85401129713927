import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // 验权
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['/login','/register','/doc'] // 不重定向白名单

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title);

  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      console.log("hasRoles",hasRoles);
      if (hasRoles) {
        next()
      }
      else {
        try {
          console.log("store.getters.token",store.getters.token);
  
          const { roles } = await store.dispatch('user/GetInfo')
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        }
        catch (err) {
          console.error(err);
          await store.dispatch('user/FedLogOut');
            Message.error(err || 'Verification failed, please login again')
            next(`/login?redirect=${to.path}`)
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      console.log("KKKKKKK",to.path);
      
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
