<template>
  <div>
    <scroll-bar>
      <template v-if="!isCollapse">
        <div class="logo" v-if="domain == 'admin.adwanji.com'">
          <img :src="mc_logo" style="width: 140px;" />
        </div>
        <div class="logo" v-else-if="domain == 'adm.adbaixiang.com'">
          <img :src="bxg_logo" style="width: 140px;" />
        </div>
        <div class="logo" v-else-if="domain == 'adx.adyuedong.com' || domain == 'localhost:8080'">
          <img :src="ydtd_logo" style="width: 140px;" />
        </div>
        <div class="logo"  v-else-if="domain == 'adx.adxiuqia.com' || domain == 'pub.adxiuqia.com'">
          <img :src="xiuqia_logo" style="width: 140px;" />
        </div>
        <div class="logo" v-else-if="domain == 'adx.huazirc.com'">
          <img :src="rongchuang_logo" style="width: 140px;" />
        </div>
        <div class="logo" v-else-if="domain == 'adx.afls.adbaixiang.com'"
          style="padding-left:0px; text-align: center; ">
          阿芙洛思广告
        </div>
        <div class="logo" v-else style="padding-left:0px; text-align: center; ">

        </div>
      </template>
      <div style="height: 48px"></div>
      <el-menu mode="vertical" :show-timeout="200" :default-active="$route.path" :collapse="isCollapse"
        background-color="#304156" text-color="#eeeeee" active-text-color="#ffffff">
        <sidebar-item :routes="permission_routes"></sidebar-item>
      </el-menu>

    </scroll-bar>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import ScrollBar from "@/components/ScrollBar";
import mc_logo from '@/assets/meicheng/logo2.png';
import bxg_logo from '@/assets/baixiangguo/logo2.png';
import ydtd_logo from '@/assets/ydtd/ydlogo2.png';
import xiuqia_logo from '@/assets/xiuqia/logo2.png';
import rongchuang_logo from '@/assets/rongchuang/logo2.png';
export default {
  components: { SidebarItem, ScrollBar },
  data() {
    return {
      mc_logo, bxg_logo, ydtd_logo, xiuqia_logo, rongchuang_logo,
    }
  },
  computed: {
    ...mapGetters(['permission_routes', "sidebar"]),
    routes() {
      console.log("this.$router.options.routes", this.$router.options.routes);
      return this.$router.options.routes;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },

    domain: function () {
      return window.location.host;
    }


  }
};
</script>
<style>
.logo {
  color: white;
  padding: 10px 0px 0px 12px;
  height: 50px;
  font-size: 24px;
  font-family: 宋体;

}

.logo .title {
  font-size: 28px;
  color: #efefef;
  letter-spacing: 15px;
}

.logo .subtitle {
  font-size: 14px;
  color: white;
  line-height: 20px;
  letter-spacing: 6px;
}
</style>
