let MyPlugin = {};
MyPlugin.install = function (Vue, options) {
  let companyList = null;



  Vue.prototype.$log = function (msg) {
    console.log(msg);
  }
  Vue.prototype.$error = function (title, msg) {
    this.$notify.error({
      title: title,
      message: msg
    });
  }
  Vue.prototype.$success = function (title, msg) {
    this.$notify.success({
      title: title,
      message: msg
    });
  }



  Vue.prototype.$getUser = function () {
    var data = JSON.parse(sessionStorage.getItem("zsuserinfo"));
    let user = {};
    if (data) {
      //console.log(data);
      let userName = data.userInfo.name;
      let loginName = data.userInfo.userName;
      if (userName) {
        user.name = userName;
      } else {
        user.name = loginName;
      }
      user.role = data.roleInfo.displayName;
      //user.orgname = data.organizationUnitsInfo.displayName;
    }
    return user;
  }
  Vue.prototype.$validateMoney = (rule, value, callback) => {
    if (!value || value == "" || value == null) {
      callback();
      return;
    }
    var reg = RegExp("^([0-9]+|[0-9]+\.{0,1}[0-9]{1,2})$");
    if (!reg.test(value)) {
      callback(new Error("请输放正确金额"));
    } else {
      callback();
    }
  };
  Vue.prototype.$validateMinusMoney = (rule, value, callback) => {
    if (!value || value == "" || value == null) {
      callback();
      return;
    }
    var reg = RegExp("^((-)?[0-9]+|(-)?[0-9]+\.{0,1}[0-9]{1,2})$");
    if (!reg.test(value)) {
      callback(new Error("请输放正确金额"));
    } else {
      callback();
    }
  };

  Vue.prototype.$validateRequiredMoney = (rule, value, callback) => {
    var reg = RegExp("^((-)?[0-9]+|[0-9]+\.{0,1}[0-9]{1,2})$");
    if (!reg.test(value)) {
      callback(new Error("请输放正确金额"));
    } else {
      callback();
    }
  };
  Vue.prototype.$validateRequiredMinusMoney = (rule, value, callback) => {
    var reg = RegExp("^((-)?[0-9]+|(-)?[0-9]+\.{0,1}[0-9]{1,2})$");
    if (!reg.test(value)) {
      callback(new Error("请输放正确金额"));
    } else {
      callback();
    }
  };
  Vue.prototype.$validatePercent = (rule, value, callback) => {
    var reg = RegExp("^([0-9]+|[0-9]+\.{0,1}[0-9]{1,2})$");
    if (!reg.test(value)) {
      callback(new Error("请输放正确比例"));
    } else {
      if (value < 0 || value > 100) {
        callback(new Error("请输放正确比例，0-100"));
      } else
        callback();
    }
  };
  Vue.prototype.$converArray = function (obj) {
    var array = [];
    if (Array.isArray(obj)) {
      obj.forEach(element => {
        array.push(element)
      });
    }
    else {
      array.push(obj)
    }
    return array;

  }

}
export default MyPlugin