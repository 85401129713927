import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: "/api", // api的base_url
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    // console.log("res", response.data);
    /**
    * code为非200是抛错 可结合自己业务进行修改
    */
    const res = response.data
    if (response.config.baseURL != "/api") {
      return response
    }
    else if (response.config.responseType == "blob") {
      var filename = response.headers//下载后文件名
      filename = filename["content-disposition"]
      console.log("filename", filename);
      filename = filename.split(";")[1].split("filename*=utf-8''")[1];
      console.log("filename", filename);
      var blob = new Blob([response.data])
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = filename;
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象 
    }
    else if (res.code !== 200) {
      Message({
        message: res.msg || res.Message || res,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    if (error.response && error.response.status == 401) {
      MessageBox.alert('您未登录系统，请重新登录。', '提示', {
        confirmButtonText: '重新登录',
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        store.dispatch('user/FedLogOut').then(() => {
          location.reload()// 为了重新实例化vue-router对象 避免bug
        })
      })
    } else if (error.response && error.response.status == 403) {
      MessageBox.alert('您无权访问此功能！', '提示', {
        confirmButtonText: '重新登录',
        showCancelButton: true,
        showClose: false,
        type: 'warning'
      }).then(() => {
        store.dispatch('user/FedLogOut').then(() => {
          location.reload()// 为了重新实例化vue-router对象 避免bug
        })
      })
    } else {
      Message({
        message: error.toString(),
        type: 'error',
        duration: 3 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
