let slotTypes =
    [
        { id: 1, name: "横幅" },
        { id: 2, name: "插屏" },
        { id: 3, name: "开屏" },
        { id: 4, name: "信息流" },
        { id: 5, name: "视频" },
    ];
let publisherSlotTypes =
    [
        { id: 1, name: "横幅广告", disable: true },
        { id: 2, name: "插屏广告", disable: true },
        { id: 3, name: "开屏广告", des: "用户启动或切换回App时展示的广告", img: "~@/assets/images/splash.svg" },
        { id: 4, name: "信息流广告", des: "App推荐列表（信息流）里展示的广告", img: "feed" },
        { id: 5, name: "激励广告", des: "用户选择观看视频广告来换取应用或游戏内的奖励", img: "rewarded" },
    ];


let slotStatus =
    [
        { id: 0, name: "禁用", type: "danger" },
        // { id: 1, name: "测试", type: "warning" },
        { id: 2, name: "正常", type: "default" },
    ];
let slotState =
    [
        { id: 1, name: "测试", type: "warning" },
        { id: 2, name: "正式", type: "default" },
    ];
let appTypes =
    [
        { id: 0, name: "Android" },
        { id: 1, name: "iOS" },
        { id: 3, name: "快应用" },
    ];

let auditStatus =
    [
        { id: 0, name: "待审核" },
        { id: 1, name: "审核通过" },
        { id: 2, name: "审核拒绝" },
    ];

let slotSettingStatus =
    [
        { id: 0, name: "关闭", type: "info" },
        { id: 1, name: "开启", type: "success" },
    ];

let priceType =
    [
        { id: 1, name: "目标价" },
        { id: 2, name: "实时竞价" },
    ];


let renderingType =
    [
        { id: 1, name: "SDK渲染" },
        { id: 2, name: "开发者自渲染" },
    ];
let appCategory = [
    {
        value: 'zhinan', label: '指南', children: [
            { value: 'zhinan', label: '指南', }
        ]
    }
];
let apiTypes =
    [
        { id: 1, name: "标准API" },
        { id: 2, name: "自定义API" },
        { id: 3, name: "SDK" },
    ];

let controlPriceStatus =
    [
        { id: 0, name: "关闭", type: "info" },
        { id: 1, name: "开启", type: "success" },
    ];
let pushState =
    [
        { id: 0, name: "未发布", type: "info" },
        { id: 1, name: "已发布", type: "success" },
    ];
let getEmunName = function (emun, value) {
    let name = "-";
    if (value === null || value === "") return name;
    emun.forEach(element => {
        if (element.id === value) name = element.name;
    });
    if (name == "-")
        name = value;
    return name;
};
let roles = [
    { name: "管理员", id: "ADXADMIN" },
    { name: "商务", id: "ADXDB" },
    { name: "运营", id: "ADXOP" },
    { name: "媒体平台用户", id: "SSPADMIN" },
    { name: "应用开发者", id: "PUBLISHER" }
]
export default {
    slotTypes,
    publisherSlotTypes,
    slotStatus,
    appTypes,
    auditStatus,
    slotSettingStatus,
    priceType,
    slotState,
    renderingType,
    appCategory,
    roles,
    getEmunName,
    apiTypes,
    controlPriceStatus,
    pushState
}